import Colors from '../components/Colors';
import {
  faBible,
  faMobileAlt,
  faVideo,
  faStoreAlt,
  faNewspaper,
  faLaptop,
} from '@fortawesome/free-solid-svg-icons';

const Categories = {
  keys: {
    apps: 'apps & desktop software',
    cb: 'cb updates',
    digital: 'digital service providers',
    life: 'life bytes',
    stores: 'stores & websites',
    tv: 'tv & movies',
  },
  values: {
    'apps & desktop software': 'apps',
    'cb updates': 'cb',
    'digital service providers': 'digital',
    'life bytes': 'life',
    'stores & websites': 'stores',
    'tv & movies': 'tv',
  },
  urls: {
    apps: '/apps-desktop-software/',
    cb: '/cb-updates/',
    digital: '/digital-service-providers/',
    life: '/life-bytes/',
    stores: '/stores-websites/',
    tv: '/tv-movies/',
  },
  colors: {
    apps: Colors.categoryApps,
    cb: Colors.categoryCb,
    digital: Colors.categoryDigital,
    life: Colors.categoryLife,
    stores: Colors.categoryStores,
    tv: Colors.categoryTv,
  },
  icons: {
    apps: faMobileAlt,
    cb: faNewspaper,
    digital: faLaptop,
    life: faBible,
    stores: faStoreAlt,
    tv: faVideo,
  },
};

export default Categories;
