import ArticleLinks from "../../../../src/components/ArticleLinks";
import ArticleHeading from "../../../../src/components/ArticleHeading";
import BibleVerse from "../../../../src/components/BibleVerse";
import StarRating from "../../../../src/components/StarRating";
import ImageCredit from "../../../../src/components/ImageCredit";
import RecommendedArticles from "../../../../src/components/RecommendedArticles";
import ReviewNotice from "../../../../src/components/ReviewNotice";
import React from 'react';
export default {
  ArticleLinks,
  ArticleHeading,
  BibleVerse,
  StarRating,
  ImageCredit,
  RecommendedArticles,
  ReviewNotice,
  React
};