import React from 'react';
import { css } from '@emotion/core';
import Colors from './Colors';
import StarRating from './StarRating';

//TODO: I need to clean this up and add more header types perhaps

const versionArticleHeader = ({ heading, text }) => {
  let customCSS = css`
    padding: 0.25rem 0;
    border-radius: 1em;
    background: ${Colors.secondary};
    text-align: center;
    margin: 2rem 0 !important;
    color: ${Colors.light} !important;
  `;

  if (heading === '1') return <h1 css={customCSS}>{text}</h1>;
  if (heading === '2') return <h2 css={customCSS}>{text}</h2>;
  if (heading === '3') return <h3 css={customCSS}>{text}</h3>;
  if (heading === '4') return <h4 css={customCSS}>{text}</h4>;
  if (heading === '5') return <h5 css={customCSS}>{text}</h5>;
};

const versionTopBottomBorderLines = ({ heading, text, score }) => {
  let customCSS = css`
    padding: 0.25rem 0;
    border-top: ${Colors.secondary} 1px solid;
    border-bottom: ${Colors.secondary} 1px solid;
    text-align: center;
    margin: 2rem 0 !important;
    color: ${Colors.secondary} !important;
  `;

  if (heading === '1') {
    return (
      <div>
        <h1 css={customCSS}>{text}</h1>
        {
          score ? <div css={css`
                  font-size: 18px;
                  margin: 0 auto;
                  width: max-content;
                  margin-bottom: 1rem;
                `}><StarRating score={score} /></div> : ''
        }
      </div>
    )
  }

  if (heading === '2') {
    return (
      <div>
        <h2 css={customCSS}>{text}</h2>
        {
          score ? <div css={css`
                  font-size: 18px;
                  margin: 0 auto;
                  width: max-content;
                  margin-bottom: 1rem;
                `}><StarRating score={score} /></div> : ''
        }
      </div>
    )
  }
  if (heading === '3') {
    return (
      <div>
        <h3 css={customCSS}>{text}</h3>
        {
          score ? <div css={css`
                  font-size: 18px;
                  margin: 0 auto;
                  width: max-content;
                  margin-bottom: 1rem;
                `}><StarRating score={score} /></div> : ''
        }
      </div>
    )
  }

  if (heading === '4') {
    return (
      <div>
        <h4 css={customCSS}>{text}</h4>
        {
          score ? <div css={css`
                  font-size: 18px;
                  margin: 0 auto;
                  width: max-content;
                  margin-bottom: 1rem;
                `}><StarRating score={score} /></div> : ''
        }
      </div>
    )
  }

  if (heading === '5') {
    return (
      <div>
        <h5 css={customCSS}>{text}</h5>
        {
          score ? <div css={css`
                  font-size: 18px;
                  margin: 0 auto;
                  width: max-content;
                  margin-bottom: 1rem;
                `}><StarRating score={score} /></div> : ''
        }
      </div>
    )
  }

  if (heading === '6') {
    return (
      <div>
        <h6 css={customCSS}>{text}</h6>
        {
          score ? <div css={css`
                  font-size: 18px;
                  margin: 0 auto;
                  width: max-content;
                  margin-bottom: 1rem;
                `}><StarRating score={score} /></div> : ''
        }
      </div>
    )
  }
};

const versionNormalSpaced = ({ heading, text, score }) => {
  let customCSS = css`
    padding: 0.25rem 0;
    margin: 2rem 0 !important;
  `;

  if (heading === '1') return <h1 css={customCSS}>{text}</h1>;
  if (heading === '2') return <h2 css={customCSS}>{text}</h2>;
  if (heading === '3') return <h3 css={customCSS}>{text}</h3>;
  if (heading === '4') return <h4 css={customCSS}>{text}</h4>;
  if (heading === '5') return <h5 css={customCSS}>{text}</h5>;
};

const ArticleHeading = ({ heading, text, version, score }) => {
  if (!heading || !text) return;

  if (version === 'versionArticleHeader')
    return versionArticleHeader({ heading, text, score });

  if (version === 'versionTopBottomBorderLines')
    return versionTopBottomBorderLines({ heading, text, score });

  if (version === 'versionNormalSpaced')
    return versionNormalSpaced({ heading, text, score });

  return <></>;
};

export default ArticleHeading;
