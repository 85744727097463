import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { css } from '@emotion/core';

const StyledText = styled('span')`
  display: inline-block;
  font-size: 1.15rem;
  font-style: italic;
  color: ${Colors.graySteel};
  font: 400 1rem 'Open Sans';
`;

const StyledSource = styled('span')`
  display: inline-block;
  font-style: italic;
  color: ${Colors.graySteel};
  padding: 0.25rem 0;
  font: 400 1rem 'Open Sans';
`;

const ImageCredit = ({ text, source }) => (
  <div
    css={css`
      width: 96%;
      margin: .25rem auto .75rem auto;

      @media (min-width: 640px) {
        width: 80%;
      }

      @media (min-width: 800px) {
        width: 70%;
      }
    `}
  >
    <div
      css={css`
        text-align: center;
      `}
    >
      <StyledText>{text}</StyledText> <StyledSource>{source}</StyledSource>
    </div>
  </div>
);

export default ImageCredit;
