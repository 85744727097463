import React from 'react';
import { css } from '@emotion/core';
import Colors from './Colors';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const starMap = {
  "0": ["zero", "zero", "zero", "zero", "zero"],
  ".5": ["half", "zero", "zero", "zero", "zero"],
  "1": ["full", "zero", "zero", "zero", "zero"],
  "1.5": ["full", "half", "zero", "zero", "zero"],
  "2": ["full", "full", "zero", "zero", "zero"],
  "2.5": ["full", "full", "half", "zero", "zero"],
  "3": ["full", "full", "full", "zero", "zero"],
  "3.5": ["full", "full", "full", "half", "zero"],
  "4": ["full", "full", "full", "full", "zero"],
  "4.5": ["full", "full", "full", "full", "half"],
  "5": ["full", "full", "full", "full", "full"],
}


const FullStar = (key) => {
  return (
    <FontAwesomeIcon
      key={key}
      css={css`
        color: ${Colors.main};
      `}
      icon={faStar}
      size={'sm'}
      width="10"
    />
  )
};

const HalfStar = (key) => {
  return (
    <FontAwesomeIcon
      key={key}
      css={css`
        color: ${Colors.main};
      `}
      icon={faStarHalf}
      size={'sm'}
      width="10"
    />
  )
};

const ZeroStar = (key) => {
  return (
    <FontAwesomeIcon
      key={key}
      css={css`
        color: ${Colors.starFade};
      `}
      icon={faStar}
      size={'sm'}
      width="10"
    />
  )
};

const CreateStar = ({key, star}) => {
  if (star === "full") return <FullStar key={key} />
  if (star === "half") return <HalfStar key={key} />
  if (star === "zero") return <ZeroStar key={key} />

  // In case I pass something other than what is expected, let it return nothing
  return <></>
}


const rating = (score) => {
  const starArray = starMap[score];

  if (score === undefined || score === null || starArray === undefined) return;

  return (
    <>
     {starArray.map((star, index) => (
        <CreateStar key={index.toString()} star={star}></CreateStar>
      ))}
    </>
  )
};


const StarRating = ({ score }) => {
  if (!score) return;
  return rating(score);
};

export default StarRating;
