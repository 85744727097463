import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { css } from '@emotion/core';

const StyledHead = styled('span')`
  display: inline-block;
  font-size: 1.15rem;
  font-style: italic;
  color: ${Colors.graySteel};
  font: 600 1rem 'Open Sans';
`;

const StyledText = styled('span')`
  display: inline-block;
  font-style: italic;
  color: ${Colors.graySteel};
  padding: 0.25rem 0;
  font: 400 1rem 'Open Sans';
`;

const head = "About the reviewer:";

const text = "A little about me just so you know where I'm coming from: software engineer (from entrepreneur-land to the enterprise) with an eye for design & writing skills to boot for almost 30 years.";

const ReviewNotice = () => (
  <div
    css={css`
      width: 96%;
      margin: .25rem auto .75rem auto;

      @media (min-width: 640px) {
        width: 80%;
      }

      @media (min-width: 800px) {
        width: 70%;
      }
    `}
  >
    <div
      css={css`
        text-align: center;
      `}
    >
      <StyledHead>{head}</StyledHead> <StyledText>{text}</StyledText>
    </div>
  </div>
);

export default ReviewNotice;
