import React from 'react';
import Colors from './Colors';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SpoilerAlert = () => (
  <div
    css={css`
      width: 80%;
      margin: 1rem auto;
      border-radius: 10px;
      overflow: hidden;
      background: ${Colors.main};
      color: ${Colors.light};
      padding: 0.5rem 0;
    `}
  >
    <div
      css={css`
        text-align: center;
        font-size: 2rem;
      `}
    >
      <FontAwesomeIcon
        css={css`
          color: ${Colors.light} !important;
          margin-right: 0.5rem;
        `}
        icon={faEyeSlash}
        size={'sm'}
        width="28"
      />
      <span>SPOILER ALERT!</span>
    </div>
    <div
      css={css`
        text-align: center;
        font-size: 1rem;
      `}
    >
      Reading past here could spoil something!
    </div>
  </div>
);

export default SpoilerAlert;
