import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import URLMap from '../constants/URLMap';
import { Link } from 'gatsby';

const ExternalStyledLink = styled('a')`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${Colors.main};
  &:hover {
    color: ${Colors.secondary};
    text-decoration: none;
  }
`;

const InternalStyledLink = styled(Link)`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${Colors.main};
  &:hover {
    color: ${Colors.secondary};
    text-decoration: none;
  }
`;

const renderLink = (articleLink, articleLinkName, internal) => {
  return internal ? (
    <InternalStyledLink to={articleLink}>{articleLinkName}</InternalStyledLink>
  ) : (
    <ExternalStyledLink target={'_blank'} href={articleLink}>
      {articleLinkName}
    </ExternalStyledLink>
  );
};

const findLink = link => {
  let needle;
  Object.keys(URLMap).forEach(function(key) {
    Object.keys(URLMap[key]).forEach(function(subKey) {
      if (link === subKey) {
        needle = URLMap[key][subKey];
        return true;
      }
    });
  });

  return needle;
};

const selectLink = (link, name, internal) => {
  const linkDetails = findLink(link);
  const articleLink = linkDetails.url;
  let articleLinkName = linkDetails && linkDetails.name ? linkDetails.name : '';
  if (name) {
    articleLinkName = name;
  }
  return articleLink ? (
    renderLink(articleLink, articleLinkName, internal)
  ) : (
    <>{articleLinkName}</>
  );
};

const ArticleLink = ({ link, name, internal }) =>
  selectLink(link, name, internal);

export default ArticleLink;
