import ArticleLinks from "../../../../src/components/ArticleLinks";
import SpoilerAlert from "../../../../src/components/SpoilerAlert";
import RecommendedArticles from "../../../../src/components/RecommendedArticles";
import CharacterList from "../../../../src/components/CharacterList";
import React from 'react';
export default {
  ArticleLinks,
  SpoilerAlert,
  RecommendedArticles,
  CharacterList,
  React
};