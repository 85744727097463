const social = {
  facebook: {
    name: "CB Facebook Page",
    url: "https://fb.me/ChristianBytes"
  },
  twitter: {
    name: "@BytesChristian",
    url: "https://twitter.com/BytesChristian"
  }
};

export default social;