import ArticleLinks from "../../../../src/components/ArticleLinks";
import RecommendedArticles from "../../../../src/components/RecommendedArticles";
import ArticleHeading from "../../../../src/components/ArticleHeading";
import ImageCredit from "../../../../src/components/ImageCredit";
import React from 'react';
export default {
  ArticleLinks,
  RecommendedArticles,
  ArticleHeading,
  ImageCredit,
  React
};