import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { css } from '@emotion/core';

const StyledVerse = styled('span')`
  display: inline-block;
  font-size: 1.15rem;
  font-style: italic;
  color: ${Colors.main};
`;

const StyledReference = styled('span')`
  display: inline-block;
  font-size: 1.25rem;
  font-style: italic;
  color: #5d6669;
  padding: 0.25rem 0;
`;

const BibleVerse = ({ location, verse, translation }) => (
  <div
    css={css`
      width: 96%;
      margin: 2rem auto;

      @media (min-width: 640px) {
        width: 80%;
      }

      @media (min-width: 800px) {
        width: 70%;
      }
    `}
  >
    <div
      css={css`
        text-align: center;
      `}
    >
      <img src="https://www.christianbytes.com/jesus-bible.png" alt="Word of God Bible Verse Callout" />
    </div>

    <div
      css={css`
        margin: 0 auto;
        border-top: 3px solid #223f71;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        <StyledReference>
          {location} ({translation})
        </StyledReference>
      </div>
      <div
        css={css`
          padding: 0.25rem 1rem;
          text-align: center;
        `}
      >
        <StyledVerse>{verse}</StyledVerse>
      </div>
    </div>
  </div>
);

export default BibleVerse;
