const categories = {
  categoryApps: {
    name: "Apps & Desktop Software",
    url: "/apps-desktop-software/"
  },
  categoryDigital: {
    name: "Digital Service Providers",
    url: "/digital-service-providers/"
  },
  categoryTV: {
    name: "TV & Movies",
    url: "/tv-movies/"
  },
  categoryLife: {
    name: "Life Bytes",
    url: "/life-bytes/"
  },
  categoryStores: {
    name: "Stores & Websites",
    url: "/stores-websites/"
  },
  categoryCB: {
    name: "CB Updates",
    url: "cb-updates/"
  },
};

export default categories;