const armory = {
  armoryBible: {
    name: "Armory: Bible Apps & Websites",
    url: "/armory/bible-apps-sites"
  },
  armoryPodcasts: {
    name: "Armory: Christian Podcasts",
    url: "/armory/christian-podcasts"
  },
  armoryRadio: {
    name: "Armory: Christian Radio Apps & Listen Live Sites",
    url: "/armory/christian-radio"
  },
  armoryTV: {
    name: "Armory: Christian TV & Movie Services",
    url: "/armory/christian-tv-movies-services"
  },
  armoryChurch: {
    name: "Armory: Church / Teaching Sites & Apps",
    url: "/armory/christian-church-teaching-sites"
  },
  armorySpotify: {
    name: "Armory: Christian Spotify Playlists",
    url: "/armory/christian-spotify-playlists"
  },
  armoryYouTube: {
    name: "Armory: Christian YouTube Channels",
    url: "/armory/christian-youtube-channels"
  },
  armoryTwitter: {
    name: "Armory: Christian Twitter Accounts",
    url: "/armory/christian-twitter-accounts"
  },
  armoryStores: {
    name: "Armory: Christian Stores",
    url: "/armory/christian-stores"
  },
  armoryNews: {
    name: "Armory: Christian News",
    url: "/armory/christian-news"
  },
};

export default armory;