import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { css } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Category from '../constants/CategoryMap';

const InternalStyledLink = styled(Link)`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${Colors.main};
  &:hover {
    color: ${Colors.secondary};
    text-decoration: none;
  }
`;

const UpcomingTitle = styled('div')`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${Colors.graySteel};
`;

const UpcomingTag = styled('div')`
  display: inline-block;
  font-size: 1rem;
  color: #f2f2f0;
  background: #6d35aa;
  border-radius: 0.75rem;
  width: 6rem;
  height: 1.4rem;
  text-align: center;
  font-weight: 200;
`;

const Excerpt = styled('div')`
  font-size: 0.875rem;
  color: black;
  text-align: left;
  font-weight: 300;
  margin-bottom: 0.25rem;
`;

const NavigationAlert = ({ next, previous, upcoming }) => {
  if (upcoming) {
    return <UpcomingTag>UPCOMING</UpcomingTag>;
  }

  return <></>;
};

const ArticleBlade = ({ data }) => {
  const frontmatter = data.frontmatter;
  const image =
    frontmatter.image &&
    frontmatter.image.childImageSharp &&
    frontmatter.image.childImageSharp.fluid.src;
  const categoryURL = Category.urls[Category.values[frontmatter.category]];
  const articleURL = categoryURL + frontmatter.slug;
  const upcomingArticle = frontmatter.status === 'PLANNED' ? true : false;
  const imageALT = 'Image for: ' + frontmatter.title + ' - ChristianBytes.com';

  return (
    <div
      css={css`
        display: flex;
        margin: 1rem 1rem 1rem 0;
      `}
    >
      <div>
        {upcomingArticle ? (
          <img
            src="https://www.christianbytes.com/article-coming-soon.jpg"
            alt="ChristianBytes - Article Coming Soon"
            css={css`
              border-radius: 0.75rem;
            `}
          />
        ) : (
          <InternalStyledLink to={articleURL}>
            <img
              alt={imageALT}
              src={image}
              css={css`
                border-radius: 0.75rem;
              `}
            />
          </InternalStyledLink>
        )}
      </div>
      <div
        css={css`
          padding-left: 1rem;
        `}
      >
        <NavigationAlert
          upcoming={upcomingArticle}
          next={frontmatter.next}
          previous={frontmatter.previous}
        />
        {upcomingArticle ? (
          <UpcomingTitle>{frontmatter.planned}</UpcomingTitle>
        ) : (
          <InternalStyledLink to={articleURL}>
            {frontmatter.title}
          </InternalStyledLink>
        )}
        {upcomingArticle ? '' : <Excerpt>{frontmatter.excerpt}</Excerpt>}
      </div>
    </div>
  );
};

const GetData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            title
            slug
            tags
            status
            planned
            category
            excerpt
            private
            author
            date
            image {
              childImageSharp {
                fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

const ContentCalendarLinks = ({ period, planned, header }) => {
  let data = GetData();
  const postedItems = data.allMdx.nodes.filter(
    node =>
      node.frontmatter.date &&
      node.frontmatter.status &&
      node.frontmatter.status === 'PUBLISHED' &&
      node.frontmatter.date.indexOf(period) !== -1
  );

  const postedAndPlanned = data.allMdx.nodes.filter(
    node =>
      node.frontmatter.date &&
      node.frontmatter.status &&
      (node.frontmatter.status === 'PUBLISHED' ||
        (node.frontmatter.status === 'PLANNED' && node.frontmatter.planned)) &&
      node.frontmatter.date.indexOf(period) !== -1
  );

  const contentItems = planned ? postedAndPlanned : postedItems;

  return (
    <>
      {header && contentItems.length > 0 ? <h4>{header}</h4> : ''}
      {contentItems.map((record, index) => (
        <ArticleBlade key={index.toString()} data={record} header={header} />
      ))}
    </>
  );
};

export default ContentCalendarLinks;
