import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { css } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import Category from '../constants/CategoryMap';

const InternalStyledLink = styled(Link)`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${Colors.main};
  &:hover {
    color: ${Colors.secondary};
    text-decoration: none;
  }
`;

const UpcomingTitle = styled('div')`
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${Colors.graySteel};
`;

const Header = styled('div')`
  font-size: 2rem;
  background: ${Colors.graySteel};
  color: ${Colors.light};
  border-radius: 0 0.75rem 0.75rem 0;
  padding-left: 0.75rem;
  font-weight: 700;
`;

const NextTag = styled('div')`
  display: inline-block;
  font-size: 1rem;
  color: #f2f2f0;
  background: #818f67;
  border-radius: 0.75rem;
  width: 6rem;
  height: 1.4rem;
  text-align: center;
  font-weight: 200;
`;

const CurrentTag = styled('div')`
  display: inline-block;
  font-size: 1rem;
  color: #f2f2f0;
  background: ${Colors.graySteel};
  border-radius: 0.75rem;
  width: 6rem;
  height: 1.4rem;
  text-align: center;
  font-weight: 200;
`;

const UpcomingTag = styled('div')`
  display: inline-block;
  font-size: 1rem;
  color: #f2f2f0;
  background: #6d35aa;
  border-radius: 0.75rem;
  width: 6rem;
  height: 1.4rem;
  text-align: center;
  font-weight: 200;
`;

const PreviousTag = styled('div')`
  display: inline-block;
  font-size: 1rem;
  color: #f2f2f0;
  background: #abaabf;
  border-radius: 0.75rem;
  width: 6rem;
  height: 1.4rem;
  text-align: center;
  font-weight: 200;
`;

const Excerpt = styled('div')`
  font-size: 0.875rem;
  color: black;
  text-align: left;
  font-weight: 300;
  margin-bottom: 0.25rem;
`;

const RecommendationHeader = ({ title }) => {
  return <Header>{title}</Header>;
};

const NavigationAlert = ({ next, previous, upcoming, current }) => {
  if (next && !upcoming) {
    return <NextTag>NEXT</NextTag>;
  }

  if (previous && !upcoming) {
    return <PreviousTag>PREVIOUS</PreviousTag>;
  }

  if (upcoming) {
    return <UpcomingTag>UPCOMING</UpcomingTag>;
  }

  if (current) {
    return <CurrentTag>VIEWING</CurrentTag>
  }

  return <></>;
};

const ArticleBlade = ({ data }) => {
  const frontmatter = data.frontmatter;
  const image =
    frontmatter.image &&
    frontmatter.image.childImageSharp &&
    frontmatter.image.childImageSharp.fluid.src;
  const categoryURL = Category.urls[Category.values[frontmatter.category]];
  const articleURL = categoryURL + frontmatter.slug;
  const upcomingArticle =
    frontmatter.status === 'PLANNED' || frontmatter.status === 'PROPOSED'
      ? true
      : false;
  const imageALT = 'Image for: ' + frontmatter.title + ' - ChristianBytes.com';

  // It's possible that I want an upcoming item private, so return nothing
  // Need to use string compares here
  if (frontmatter.private === 'true' || frontmatter.private === 'TRUE') {
    return <></>;
  }

  return (
    <div
      css={css`
        display: flex;
        margin: 1rem 1rem 1rem 0;
      `}
    >
      <div>
        {upcomingArticle ? (
          <img
            src="https://www.christianbytes.com/article-coming-soon.jpg"
            alt="ChristianBytes - Article Coming Soon"
            css={css`
              border-radius: 0.75rem;
            `}
          />
        ) : (
          <InternalStyledLink to={articleURL}>
            <img
              alt={imageALT}
              src={image}
              css={css`
                border-radius: 0.75rem;
              `}
            />
          </InternalStyledLink>
        )}
      </div>
      <div
        css={css`
          padding-left: 1rem;
        `}
      >
        <NavigationAlert
          upcoming={upcomingArticle}
          next={frontmatter.next}
          previous={frontmatter.previous}
          current={frontmatter.current}
        />
        {upcomingArticle ? (
          <UpcomingTitle>{frontmatter.planned}</UpcomingTitle>
        ) : (
          <InternalStyledLink to={articleURL}>
            {frontmatter.title}
          </InternalStyledLink>
        )}
        {upcomingArticle ? '' : <Excerpt>{frontmatter.excerpt}</Excerpt>}
      </div>
    </div>
  );
};

const GetData = item => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            title
            slug
            tags
            status
            private
            planned
            category
            excerpt
            author
            date
            image {
              childImageSharp {
                fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

const RecommendedArticles = ({ recommendations, title }) => {
  let data = GetData();
  let matchingRecords = [];
  const updatedTitle = title ? title.toUpperCase() : 'READ MORE';
  recommendations.forEach(function(item) {
    const found = data.allMdx.nodes.find(
      node => node.frontmatter.slug === item.slug
    );

    if (found) {
      found.frontmatter.next = item.next ? true : false;
      found.frontmatter.previous = item.previous ? true : false;
      found.frontmatter.current = item.current ? true : false;
      matchingRecords.push(found);
    }
  });

  return (
    <>
      <RecommendationHeader title={updatedTitle} />
      {matchingRecords.map((record, index) => (
        <ArticleBlade key={index.toString()} data={record} />
      ))}
    </>
  );
};

export default RecommendedArticles;
