const main = {
  master: {
    name: "Christian Bytes",
    url: "https://www.christianbytes.com"
  },
  home: {
    name: "Christian Bytes",
    url: "/"
  },
  armory: {
    name: "Christian Armory",
    url: "/armory/"
  },
  jesus: {
    name: "Jesus",
    url: "/jesus/"
  },
  about: {
    name: "About & Contact",
    url: "/about/"
  },
  podcast: {
    name: "Podcast",
    url: "/podcast/"
  },
};

export default main;