const Colors = {
  main: '#8c3a50',
  secondary: '#abaabf',
  light: '#f2f2f0',
  darkHighlight: '#734e46',
  lightHighlight: '#a68780',
  categoryApps: '#6D35AA',
  categoryCb: '#907125',
  categoryDigital: '#003C97',
  categoryLife: '#890592',
  categoryStores: '#3E6505',
  categoryTv: '#932142',
  categoryMatchError: '#8c3a50',
  graySteel: '#5d6669',
  starFade: '#d6d6d6'
};

export default Colors;
