const SonsOfThunderCharacters = {
  simon: {
    image: 'https://www.christianbytes.com/characters/sonsofthunder/simon.png',
    name: 'Simon',
    description: 'Cruising the road, sharing the Gospel of Jesus Christ.',
    alt: "Picture of Simon's face from Sons of Thunder",
  },
  alanCarver: {
    image:
      'https://www.christianbytes.com/characters/sonsofthunder/alan-carver.png',
    name: 'Alan Carver',
    description: 'Low life preying on the circumstances of others.',
    alt: "Picture of Alan Carver's face from Sons of Thunder",
  },
  jack: {
    image: 'https://www.christianbytes.com/characters/sonsofthunder/jack.png',
    name: 'Jack',
    description:
      "Bodyguard teleported from the 1980s to enforce Carver's demands.",
    alt: "Picture of Jack's face from Sons of Thunder",
  },
  duster: {
    image: 'https://www.christianbytes.com/characters/sonsofthunder/duster.png',
    name: 'Duster',
    description: 'Nice, but not-quite-there, worker under Carver.',
    alt: "Picture of Duster's face from Sons of Thunder",
  },
  lee: {
    image: 'https://www.christianbytes.com/characters/sonsofthunder/lee.png',
    name: 'Lee',
    description:
      'Ex-Army underground fighter trying to care for his not-so-well mom.',
    alt: "Picture of Lee's face from Sons of Thunder",
  },
  angie: {
    image: 'https://www.christianbytes.com/characters/sonsofthunder/angie.png',
    name: 'Angie',
    description:
      'Girlfriend of Simon who is waiting for him to return from his pilgrimage of faith.',
    alt: "Picture of Angie's face from Sons of Thunder",
  },
  ringo: {
    image: 'https://www.christianbytes.com/characters/sonsofthunder/ringo.png',
    name: 'Ringo',
    description: "Devil's Hand Sergeant-at-Arms and all-around bad person.",
    alt: "Picture of Ringo's face from Sons of Thunder",
  },
};

export default SonsOfThunderCharacters;
