import React from 'react';
import styled from '@emotion/styled';
import Colors from './Colors';
import { css } from '@emotion/core';
import CharacterMap from '../constants/CharacterMap';

const StyledCharacterImage = styled('div')`
  display: inline-block;
  font-size: 1.15rem;
  font-style: italic;
  color: ${Colors.main};
  margin-right: 1rem;
`;

const StyledCharacterInformation = styled('div')`
  display: inline-block;
  font-size: 1.25rem;
  color: ${Colors.graySteel};
  vertical-align: middle;
`;

const StyledCharacterDescription = styled('div')`
  display: block;
  font-size: 1rem;
  font-style: italic;
  color: ${Colors.graySteel};
`;
const StyledCharacterName = styled('div')`
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: ${Colors.graySteel};
`;

const CharacterProfile = ({ character, count }) => {
  const firstMargin = count === 0 ? '1rem' : 0;
  return (
    <div
      css={css`
        margin-bottom: 1rem;
        margin-left: 0.5rem;
        display: flex;
        margin-top: ${firstMargin};
      `}
    >
      <StyledCharacterImage>
        <img
          css={css`
            height: 5rem;
            width: 5rem;
          `}
          src={character.image}
          alt={character.alt}
        ></img>
      </StyledCharacterImage>
      <StyledCharacterInformation>
        <StyledCharacterName>{character.name}</StyledCharacterName>
        <StyledCharacterDescription>
          {character.description}
        </StyledCharacterDescription>
      </StyledCharacterInformation>
    </div>
  );
};

const CharacterList = ({ characterList, characters }) => {
  const currentCharacterList = CharacterMap[characterList];
  return (
    <div
      css={css`
        border: 1px solid ${Colors.main};
        border-left: 1rem solid ${Colors.main};
        border-radius: 0.5rem;
        margin: 1rem 0;
      `}
    >
      {characters.map((character, index) => (
        <CharacterProfile
          key={index.toString()}
          count={index}
          character={currentCharacterList[character]}
        />
      ))}
    </div>
  );
};

export default CharacterList;
