const articles = {
  christianBytesLaunches: {
    name: 'Christian Bytes Launches',
    url: '/cb-updates/christian-bytes-launches',
  },
  encounterSeason2DrawsNear: {
    name: 'Encounter Season 2 Draws Near',
    url: '/tv-movies/encounter-season-two-trailer',
  },
  encounterSeason2Recap: {
    name: 'Encounter Season 2 Recap',
    url: '/tv-movies/encounter-season-two-recap',
  },
  encounterSeason2Episode1Review: {
    name: 'The Encounter S2 E1: The Gift (will be published on 08/30/2020)',
    url: '',
  },
  encounterSeason2Episode2Review: {
    name:
      'The Encounter S2 E2: Best Worst Day (will be published on 08/30/2020)',
    url: '',
  },
  encounterSeason2Episode3Review: {
    name: 'The Encounter S2 E3: Answer #2 (publishing pending)',
    url: '',
  },
  encounterSeason2Episode4Review: {
    name: 'The Encounter S2 E4: Delivery (publishing pending)',
    url: '',
  },
  encounterSeason2Episode5Review: {
    name: 'The Encounter S2 E5: Numbered (publishing pending)',
    url: '',
  },
  encounterSeason2Episode6Review: {
    name: 'The Encounter S2 E6: Crosshairs (publishing pending)',
    url: '',
  },
  encounterSeason2Episode7Review: {
    name: 'The Encounter S2 E7: Homeward Bound (publishing pending)',
    url: '',
  },
  encounterSeason2Episode8Review: {
    name: 'The Encounter S2 E8: The Void (publishing pending)',
    url: '',
  },
  sonsOfThunderSeason1Recap: {
    name: 'Sons of Thunder Season 1 Recap',
    url: '/tv-movies/sons-of-thunder-season-one-recap',
  },
  sonsOfThunderSeason1Episode1Recap: {
    name:
      'Sons of Thunder Season 1 Episode 1: Mi Casa Es Su Casa Recap (Recap)',
    url: '/tv-movies/sons-of-thunder-s1-e1-recap',
  },
  sonsOfThunderSeason1Episode2Recap: {
    name:
      'Sons of Thunder Season 1 Episode 2: The Blind Fighting the Blind (Recap)',
    url: '/tv-movies/sons-of-thunder-s1-e2-recap',
  },
  sonsOfThunderSeason1Episode3Recap: {
    name: 'Sons of Thunder Season 1 Episode 3: Hunter Hunted (Recap)',
    url: '/tv-movies/sons-of-thunder-s1-e3-recap',
  },
  sonsOfThunderSeason1Episode4Review: {
    name:
      'Sons of Thunder Season 1 Episode 4: Time and Chance (Review & Thoughts)',
    url: '/tv-movies/sons-of-thunder-s1-e4-review-thoughts',
  },
  sonsOfThunderSeason1Episode5Review: {
    name: 'Sons of Thunder Season 1 Episode 5: Deep Water (Review & Thoughts)',
    url: '/tv-movies/sons-of-thunder-s1-e5-review-thoughts',
  },
  sonsOfThunderSeason1Episode6Review: {
    name: "Sons of Thunder Season 1 Episode 6: Brother's Keeper - Part 1",
    url: '',
  },
  sonsOfThunderSeason1Episode7Review: {
    name: "Sons of Thunder Season 1 Episode 7: Brother's Keeper - Part 2",
    url: '',
  },
  vindicationIntro: {
    name: 'Vindication: New Weekly Series from Pure Flix',
    url: '/tv-movies/vindication-pure-flix',
  },
  vindicationSeason1: {
    name: 'Vindication: Season One Recap (publishing pending)',
    url: '',
  },
  tribulationRisingTeachingSeries: {
    name: 'Review: Tribulation Rising Teaching Series',
    url: '/life-bytes/tribulation-modern-technology-series-review',
  },
  christianCinemaReview: {
    name: 'Christian Cinema Review',
    url: '/digital-service-providers/christian-cinema-review',
  },
  oliveTreeBibleAppReview: {
    name: 'Review: Olive Tree Bible App (will be published on 09/13/2020)',
    url: '',
  },
  givingMembershipReview: {
    name: 'Review: Giving Membership (will be published on 08/16/2020)',
    url: '',
  },
  vidAngelReviewOverview: {
    name: 'VidAngel Review: Overview',
    url: '/digital-service-providers/vidangel-review-overview'
  },
  vidAngelReviewGettingStarted: {
    name: 'VidAngel Review: Getting Started',
    url: '/digital-service-providers/vidangel-review-getting-started'
  },
  vidAngelReviewMyExperience: {
    name: 'VidAngel Review: My Experience',
    url: '/digital-service-providers/vidangel-review-my-experience'
  },
  dryBarComedyPlusReview: {
    name: 'Dry Bar Comedy+ Review',
    url: '/digital-service-providers/dry-bar-comedy-review'
  },
  jesus: {
    name: 'Jesus',
    url: '/jesus/'
  },
  youVersionBibleAppReview: {
    name: 'YouVersion Bible App Review',
    url: '/apps-desktop-software/youversion-bible-app-review'
  }
};

export default articles;
