import Main from './URLS/main';
import Armory from './URLS/armory';
import Categories from './URLS/categories';
import Articles from './URLS/articles';
import External from '../data/externalLinks';
import Social from './URLS/social';

export default {
  Main,
  Armory,
  Categories,
  Articles,
  External,
  Social
};
