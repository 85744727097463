const externalLinks = {
  christianCinema: {
    url: "https://www.christiancinema.com/digital/",
    name: "Christian Cinema",
    linkType: "christiancinema"
  },
  christianCinemaAlisonsChoice: {
    url: "https://www.christiancinema.com/digital/movie/alisons-choice",
    name: "Alison's Choice",
    linkType: "christiancinema"
  },
  christianCinemaAloneYetNotAlone: {
    url: "https://www.christiancinema.com/digital/movie/alone-yet-not-alone",
    name: "Alone Yet Not Alone",
    linkType: "christiancinema"
  },
  christianCinemaBeforeTheWrath: {
    url: "https://www.christiancinema.com/digital/movie/before-the-wrath",
    name: "Before the Wrath",
    linkType: "christiancinema"
  },
  christianCinemaBeyondTheMask: {
    url: "https://www.christiancinema.com/digital/movie/beyond-the-mask",
    name: "Beyond The Mask",
    linkType: "christiancinema"
  },
  christianCinemaBibleCollectionEster: {
    url: "https://www.christiancinema.com/digital/movie/bible-collection-esther",
    name: "Bible Collection: Ester",
    linkType: "christiancinema"
  },
  christianCinemaBibleCollectionGenesis: {
    url: "https://www.christiancinema.com/digital/movie/bible-collection-genesis",
    name: "Bible Collection: Genesis",
    linkType: "christiancinema"
  },
  christianCinemaBibleCollectionJeremiah: {
    url: "https://www.christiancinema.com/digital/movie/bible-collection-jeremiah",
    name: "Bible Collection: Jeremiah",
    linkType: "christiancinema"
  },
  christianCinemaBibleCollectionSolomon: {
    url: "https://www.christiancinema.com/digital/movie/bible-collection-solomon",
    name: "Bible Collection: Solomon",
    linkType: "christiancinema"
  },
  christianCinemaBlueLikeJazz: {
    url: "https://www.christiancinema.com/digital/movie/blue-like-jazz",
    name: "Blue Like Jazz",
    linkType: "christiancinema"
  },
  christianCinemaGallowsRoad: {
    url: "https://www.christiancinema.com/digital/movie/gallows-road",
    name: "Gallows Road",
    linkType: "christiancinema"
  },
  christianCinemaGenesisParadiseLost: {
    url: "https://www.christiancinema.com/digital/movie/genesis-paradise-lost",
    name: "Genesis: Paradise Lost",
    linkType: "christiancinema"
  },
  christianCinemaGodsCompass: {
    url: "https://www.christiancinema.com/digital/movie/gods-compass",
    name: "God's Compass",
    linkType: "christiancinema"
  },
  christianCinemaGodsNotDead: {
    url: "https://www.christiancinema.com/digital/movie/gods-not-dead-p8848",
    name: "God's Not Dead",
    linkType: "christiancinema"
  },
  christianCinemaGodsNotDead2: {
    url: "https://www.christiancinema.com/digital/movie/gods-not-dead-2",
    name: "God's Not Dead 2",
    linkType: "christiancinema"
  },
  christianCinemaGodsNotDead3: {
    url: "https://www.christiancinema.com/digital/movie/gods-not-dead-3-a-light-in-darkness",
    name: "God's Not Dead 3: a Light in Darkness",
    linkType: "christiancinema"
  },
  christianCinemaHeavensWar: {
    url: "https://www.christiancinema.com/digital/movie/heavens-war",
    name: "Heaven's War",
    linkType: "christiancinema"
  },
  movieWarRoom: {
    url: "https://www.christianbook.com/war-room-blu-ray/pd/461505",
    name: "War Room",
    linkType: "movie"
  },
  christianCinemaIAmPotential: {
    url: "https://www.christiancinema.com/digital/movie/i-am-potential",
    name: "I am Potential",
    linkType: "christiancinema"
  },
  christianCinemaICanOnlyImagine: {
    url: "https://www.christiancinema.com/digital/movie/i-can-only-imagine-hd",
    name: "I Can Only Imagine",
    linkType: "christiancinema"
  },
  christianCinemaInTouch: {
    url: "https://www.christiancinema.com/digital/movie/in-touch-with-dr-charles-stanley",
    name: "In Touch with Dr. Charles Stanley - Expressing Godly Character and Your Convictions",
    linkType: "christiancinema"
  },
  christianCinemaIStillBelieve: {
    url: "https://www.christiancinema.com/digital/movie/i-still-believe",
    name: "I Still Believe (2020)",
    linkType: "christiancinema"
  },
  christianCinemaJustLetGo: {
    url: "https://www.christiancinema.com/digital/movie/just-let-go",
    name: "Just Let Go",
    linkType: "christiancinema"
  },
  christianCinemaKingdomMenRising: {
    url: "https://www.christiancinema.com/digital/movie/kingdom-men-rising",
    name: "Kingdom Men Rising",
    linkType: "christiancinema"
  },
  christianCinemaPilgrimsProgress: {
    url: "https://www.christiancinema.com/digital/movie/the-pilgrims-progress-directed-by-robert-fernandez",
    name: "The Pilgrim's Progress",
    linkType: "christiancinema"
  },
  christianCinemaPriceless: {
    url: "https://www.christiancinema.com/digital/movie/priceless",
    name: "Priceless",
    linkType: "christiancinema"
  },
  christianCinemaPrincessCut: {
    url: "https://www.christiancinema.com/digital/movie/princess-cut",
    name: "Princess Cut",
    linkType: "christiancinema"
  },
  christianCinemaSheHasAName: {
    url: "https://www.christiancinema.com/digital/movie/she-has-a-name",
    name: "She has a Name",
    linkType: "christiancinema"
  },
  christianCinemaSleeperAgent: {
    url: "https://www.christiancinema.com/digital/movie/sleeper-agent",
    name: "SleeperAgent",
    linkType: "christiancinema"
  },
  christianCinemaSonicTheHedgehog: {
    url: "https://www.christiancinema.com/digital/movie/sonic-the-hedgehog-p9439",
    name: "Sonic the Hedgehog",
    linkType: "christiancinema"
  },
  christianCinemaTheEncounter: {
    url: "https://www.christiancinema.com/digital/movie/the-encounter",
    name: "The Encounter",
    linkType: "christiancinema"
  },
  christianCinemaTheGospelOfJohn: {
    url: "https://www.christiancinema.com/digital/movie/the-gospel-of-john",
    name: "The Gospel of John",
    linkType: "christiancinema"
  },
  christianCinemaTheLionOfJudah: {
    url: "https://www.christiancinema.com/digital/movie/the-lion-of-judah",
    name: "The Lion of Judah",
    linkType: "christiancinema"
  },
  christianCinemaTheList2015: {
    url: "https://www.christiancinema.com/digital/movie/the-list-2015",
    name: "The List (2015)",
    linkType: "christiancinema"
  },
  christianCinemaThePeanutButterFalcon: {
    url: "https://www.christiancinema.com/digital/movie/he-peanut-butter-falcon",
    name: "The Peanut Butter Falcon",
    linkType: "christiancinema"
  },
  christianCinemaTheReliant: {
    url: "https://www.christiancinema.com/digital/movie/the-reliant-2019",
    name: "The Reliant (2019)",
    linkType: "christiancinema"
  },
  christianCinemaUnplanned: {
    url: "https://www.christiancinema.com/digital/movie/unplanned",
    name: "Unplanned",
    linkType: "christiancinema"
  },
  christianCinemaWildflower: {
    url: "https://www.christiancinema.com/digital/movie/wildflower",
    name: "Wildflower",
    linkType: "christiancinema"
  },
  doveGodsNotDead3: {
    url: "https://dove.org/review/12769-gods-not-dead-a-light-in-darkness/",
    name: "God's Not Dead: A Light in Darkness",
    linkType: "dove.org"
  },
  getALifeMedia: {
    url: "http://getalifemedia.com",
    name: "Get A Life Ministries",
    linkType: "getALifeMedia"
  },
  getALifeMediaModernTechnologyDVD: {
    url: "https://store.getalifemedia.com/Tribulation-Rising-Volume-2--Modern-Technology_p_225.html",
    name: "Tribulation Rising Volume 2 - Modern Technology (DVD SET)",
    linkType: "getalifemedia"
  },
  getALifeMediaModernTechnologyVimeo: {
    url: "https://vimeo.com/ondemand/moderntechnology",
    name: "Tribulation Rising Volume 2 - Modern Technology (Vimeo)",
    linkType: "vimeo"
  },
  givingCompany: {
    url: "https://www.givingcompany.com",
    name: "Giving Company",
    linkType: "givingcompany"
  },
  givingMembershipSignupPage: {
    url: "https://www.idisciple.org/signup/startpaidsignup",
    name: "Giving Membership Signup Page",
    linkType: "givingcompany"
  },
  iDisciple: {
    url: "https://www.iDisciple.org",
    name: "iDisciple.org",
    linkType: "iDisciple"
  },
  iDiscipleFeed: {
    url: "https://www.idisciple.org/feed",
    name: "iDisciple Feed",
    linkType: "givingcompany"
  },
  janMarkellCroneInterview1: {
    url: "https://www.youtube.com/watch?v=OXIoGAhApQI",
    name: "No Place to Hide (Part 1) Jan Markell w/ Billy Crone",
    linkType: "youtube"
  },
  janMarkellCroneInterview2: {
    url: "https://www.youtube.com/watch?v=uBqm-IulazM",
    name: "No Place to Hide (Part 2) Jan Markell w/ Billy Crone",
    linkType: "youtube"
  },
  pureflix: {
    url: "https://www.pureflix.com",
    name: "Pure Flix",
    linkType: "pureflix"
  },
  worldVision: {
    url: "https://www.worldvision.com",
    name: "World Vision",
    linkType: "worldvision"
  },
  worldVisionCleanWater: {
    url: "https://www.worldvision.org/our-work/clean-water",
    name: "World Vision: Clean Water",
    linkType: "worldvision"
  },
  worldVisionCleanWaterPartners: {
    url: "https://www.worldvision.org/our-work/clean-water/clean-water-partners",
    name: "World Vision: Clean Water Partners",
    linkType: "worldvision"
  },
  youVersionBibleApp: {
    url: "https://www.youversion.com/the-bible-app/",
    name: "YouVersion Bible App",
    linkType: "youversion"
  },
  vidAngel: {
    url: "https://www.vidangel.com",
    name: "VidAngel",
    linkType: "vidangel"
  },
  dryBarComedyYouTube: {
    url: "https://www.youtube.com/c/DryBarComedy/featured",
    name: "DryBar Comedy YouTube Channel",
    linkType: "vidangel"
  },
  dryBarComedyPlus: {
    url: "https://www.drybarcomedy.com/",
    name: "DryBar Comedy+",
    linkType: "vidangel"
  },
  blueLetterBibleApp: {
    url: "https://www.blueletterbible.org",
    name: "Blue Letter Bible",
    linkType: "blb"
  }
}
export default externalLinks;