import React from 'react';
const VideoLink = ({ id }) => {
  const link = `https://www.youtube-nocookie.com/embed/${id}`;
  return (
    <div>
      <iframe
        width="560"
        height="315"
        src={link}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={id}
      ></iframe>{' '}
    </div>
  );
};

export default VideoLink;
